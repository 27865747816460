
export default function NotFound() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1>通知</h1>
                    <p>本站点从2023年7月7日起停止服务，已经购买过的用户，请微信扫码加入此群进行退费。</p>
                    <p><img width={200} src="/learn-ai-wechat-group.jpg" alt="" /></p>
                    <p> 2023/07/07 12:35 </p>
                </div>
            </div>
        </div>
    )
}