import {useEffect } from "react";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react/offline";
import arrowGoBackLine from "@iconify/icons-ri/arrow-go-back-line";
import arrowRightSLine from "@iconify/icons-ri/arrow-right-s-line";

export default function Changelog() {

    useEffect(() => {
        localStorage.setItem("changelog-2023-05-28", "1")
    }, []);


    return (
        <>
            <header>
                <div className="flex justify-between items-start">
                <span className="">
                </span>
                    <div className="flex login">
                        <div className="flex items-center cursor-pointer mx-1 p-2 text-slate-500 hover:bg-slate-200 hover:bg-op-10 rounded text-1.2em">
                            <Link to={"/"} title="返回"><Icon icon={arrowGoBackLine} /></Link>
                        </div>
                    </div>
                </div>
            </header>



            <div className="flex flex-col mt-5 text-slate-800 dark:text-slate-400">
                <h2 className="text-2xl">更新日志</h2>

                <div className="flex items-center mt-6">
                    <div className="flex items-center rounded">
                        <Icon icon={arrowRightSLine} />
                    </div>
                    <p className="text-xl font-bold text-amber-600 items-center ml-2">2023-06-10</p>
                </div>
                <p className="mt-3"> 1. 新增支持微信扫码登录</p>
                <p className="mt-3"> 2. 一些细节优化</p>
                <p className="mt-3"> 3. 新增微信交流群。请使用微信扫描下面二维码加入群。</p>
                <p className="mt-3"><img width={200} src="/learn-ai-wechat-group.jpg" alt="" /></p>

                <br/>
                <br/>


                <div className="flex items-center mt-6">
                    <div className="flex items-center rounded">
                        <Icon icon={arrowRightSLine} />
                    </div>
                    <p className="text-xl font-bold text-amber-600 items-center ml-2">2023-05-28</p>
                </div>
                <p className="mt-3"> 1. 暗色主题能够保存，优化暗色主题的UI呈现效果。</p>
                <p className="mt-3"> 2. 修复输入带标签的内容展现异常的问题。</p>
                <p className="mt-3"> 3. 刷新页面清空当前的聊天记录。</p>
                <p className="mt-3"> 4. 支持宽屏幕展示。</p>
                <p className="mt-3"> 5. 重新加回来内置prompts。</p>
                <p className="mt-3"> 6. 一些交互细节优化。</p>

                <br/>
                <br/>


                <div className="flex items-center mt-6">
                    <div className="flex items-center rounded">
                        <Icon icon={arrowRightSLine} />
                    </div>
                    <p className="text-xl font-bold text-amber-600 items-center ml-2">2023-04-22</p>
                </div>
                <p className="mt-3"> 1. 对站点进行了整体重构，并迁移到了国内服务商，页面访问速度更快。</p>
                <p className="mt-3"> 2. 恢复主域名<strong>chat.cutterman.cn</strong>，备用域名停止提供服务。</p>
                <p className="mt-3"> 3. 优化了部分UI和交互，提升操作体验。</p>
                <p className="mt-3"> 4. 去掉了内置Prompts。</p>
                <p className="mt-3"> 5. 优化和AI的交互策略，降低请求错误的概率。</p>

                <br/>
                <br/>

                <div className="flex items-center mt-6">
                    <div className="flex items-center rounded">
                        <Icon icon={arrowRightSLine} />
                    </div>
                    <p className="text-xl font-bold text-amber-600 items-center ml-2">2023-04-09</p>
                </div>
                <p className="mt-3"> 1. 原有域名<strong>chat.cutterman.cn</strong>可能受到了一些影响，出现了局部地区不可用的情况，如果发现该域名无法访问，可以使用备用域名<a
                    href="https://chat1.cutterman.cn" className="text-green-500">chat1.cutterman.cn</a></p>
                <p className="mt-3"> 2. 可以添加我的微信，当遇到使用异常的时候，可以及时联系到我。 </p>
                <p className="mt-3"><img width={200} src="/wechat-qrcode.jpg" alt="" /></p>

                <br/>
                <br/>
                <div className="flex items-center mt-6">
                    <div className="flex items-center rounded text-1.2em">
                        <Icon icon={arrowRightSLine} />
                    </div>
                    <p className="text-xl font-bold text-amber-600 items-center ml-2">2023-03-26</p>
                </div>
                <p className="flex items-center mt-3 ">
                    <span>1. 支持两个设备同时登录，方便大家在手机和电脑上同时使用。</span>
                </p>
                <p className="flex items-start mt-3 ">
                    <span>2. 本地会话缓存，刷新页面会保留当前的会话。以解决当出现AI返回Error的时候，刷新页面导致会话丢失。</span>
                </p>
                <p className="flex items-start mt-3 ">
                    <span>3. 支持查看历史会话，点击会话，可以继续该会话交流。</span>
                </p>
                <p><img src="/usage.png" alt="usage"/></p>
                <p className="flex items-start mt-3 ">
                    <span><strong>说明：</strong>有一些小伙伴咨询当前API使用的版本。当前使用的是API3.0用的gtp-3.5-turbo模型。API4.0已经在申请中了，需要排队等待，待审核通过了，第一时间接上，敬请期待~</span>
                </p>
                <p><img src="/gpt-4.png" alt="gpt4"/></p>


                <br/>
                <br/>
                <div className="flex items-center mt-6">
                    <div className="flex items-center rounded text-1.2em">
                        <Icon icon={arrowRightSLine} />
                    </div>
                    <p className="text-xl font-bold text-amber-600 items-center ml-2">2023-03-19</p>
                </div>
                <p className="mt-3 ">1. 修复聊天消息无法导出图片的问题</p>
                <p className="mt-3 ">2. 启用付费使用机制，游客享有4次对话体验</p>
                <p className="mt-3 ">3. 支持手机号验证码登录</p>
                <p className="mt-3 ">4. 支持对话记录存储（查看功能下一版本开发）</p>
            </div>
        </>
    )
}
