import {useEffect, useState} from "react";
import Header from "../components/Header";
import {User} from "../utils/defines";
import {bindWechat, getUserInfo, getWechatQRCode} from "../utils/http";
import {useNavigate} from "react-router-dom";


export default function Wechat() {
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);
    const [qrCode, setQrCode] = useState<string|null>(null);
    const [sessionId, setSessionId] = useState<number>(0);

    let timeoutId: number = 0;
    let timer: number = 60;

    useEffect(() => {
        // 读取用户信息
        getUserInfo().then((res) => {
            setUser(res)
        });

        loadQrCode();
    }, []);

    function loadQrCode() {
        getWechatQRCode().then((res) => {
            if (res.errno === 0) {
                setQrCode(res.qrcodeUrl);
                setSessionId(res.sessionId);
                timer = 60;
                getStatus(res.sessionId);
            }
        });
    }

    function getStatus(sessionId: number) {
        if (timer <= 0) {
            clearTimeout(timeoutId);
            loadQrCode();
            return;
        }
        bindWechat(sessionId).then((res) => {
            if (res.errno === 0) {
                clearTimeout(timeoutId);
                navigate('/');
            } else {
                timer = timer - 2;
                timeoutId = window.setTimeout(() => {
                    getStatus(sessionId);
                }, 2000);
            }
        });

    }


    return (
        <>
            <Header user={user} />
            <div className="flex flex-col mt-5">
                <h2 className="text-2xl text-slate-600"><strong>需要绑定微信</strong></h2>
                <p className="mt-3 text-slate-600">站点后续将采用微信扫码登录，所以请您绑定微信。</p>
                <p className="mt-3 text-slate-600">用微信扫描下面二维码，关注公众号即可完成。 </p>
                <div className="mt-5">
                    {qrCode !== null && <img width={200} height={200} src={qrCode} alt="微信二维码" /> }
                </div>
            </div>
        </>

    );
}
