import {useEffect, useState} from "react";
import {bindPhone, getUserInfo, logout, subscription} from "../utils/http";
import {User} from "../utils/defines";
import {Link, useNavigate} from "react-router-dom";
import {Icon} from "@iconify/react/offline";
import arrowGoBackLine from "@iconify/icons-ri/arrow-go-back-line";
import vipCrown2Line from "@iconify/icons-ri/vip-crown-2-line";
import Header from "../components/Header";

export default function Profile() {
    const [user, setUser] = useState<User | null>(null);
    const [isVip, setIsVip] = useState<boolean>(false);
    const [isForever, setIsForever] = useState<boolean>(false);
    const [subEnd, setSubEnd] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const result = new URLSearchParams(window.location.search);
        const accessToken = result.get("access_token");
        if (accessToken != null) {
            localStorage.setItem("access_token", accessToken);
        }

        // 读取用户信息
        getUserInfo().then((res) => {
            setUser(res)
        });
        subscription().then((res) => {
            if (res.errno === 0) {
                const sub = res.subscription;
                if (sub) {
                    // 判断sub.subscribe_end是否大于当前时间
                    const now = new Date().getTime();
                    const end = new Date(sub.subscribe_end).getTime();
                    if (sub.type === 0) {
                        setIsVip(true);
                        setIsForever(true);
                    } else if (end > now) {
                        setIsVip(true);
                        setSubEnd(sub.subscribe_end);
                    }
                }
            }
            console.log(res);
        });
    }, []);

    async function onLogout() {
        const data = await logout();
        if (data.errno === 0) {
            navigate("/");
        }
    }

    function goBuy(text: string) {
        return <Link className="text-blue-600 underline" onClick={(evt) => {
            evt.preventDefault();
            //@ts-ignore
            if (typeof WeixinJSBridge != 'undefined') {
                // eslint-disable-next-line no-restricted-globals
                location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5bd47a51f29da295&redirect_uri=https://typing-api.cutterman.cn/mobile/wx-auth&response_type=code&scope=snsapi_base&state=2#wechat_redirect';
            } else {
                navigate('/buy');
            }
            //navigate('/buy?open_id=oBpUDwF8f0LdmBhFxqCjhwuVxl7M');
        }} id="buy-link" to="/buy">{text}</Link>
    }

    async function onBindPhone() {
        if (phone === '') {
            alert('请输入手机号');
            return;
        }
        const ret = await bindPhone(phone, "010101");
        if (ret.errno === 0) {
            const u = await getUserInfo();
            setUser(u);
        } else {
            alert(ret.info);
        }
    }

    return (
        <>
            <Header user={user} />
            {/*
            <header>
                <div className="flex justify-between items-start">
                <span className="">
                    {user != null && <img src={'/avatar/' + user!.avatar} alt="logo"/>}
                </span>
                    <div className="flex login">
                        <div className="flex items-center cursor-pointer mx-1 p-2 text-slate-500 hover:bg-slate-200 hover:bg-op-10 rounded text-1.2em">
                            <Link to={"/"} title="返回"><Icon icon={arrowGoBackLine} /></Link>
                        </div>
                    </div>
                </div>
            </header>
            */}
            {user != null && <div className="flex flex-col mt-5">
                <h2 className="text-2xl text-slate-600">欢迎你，<strong className="text-orange-600">{user!.name}</strong></h2>
                {user!.phone !== '' && <p className="mt-3 text-slate-600"><strong>登录账号：</strong>
                    <span className="text-purple-600">{user!.phone}</span>
                </p>}
                {user!.phone === '' && <p className="mt-3 text-slate-600">
                    <strong>登录账号：</strong>
                    <input
                        className="ml-3 resize-none px-2 py-2 rounded text-slate-7 dark:text-slate-500 bg-slate-200 bg-op-15 focus:bg-op-20 focus:ring-0 focus:outline-none placeholder:text-slate-400 placeholder:text-slate-400 placeholder:op-40"
                        placeholder={"请输入手机号"}
                        value={phone}
                        onInput={(e: any) => setPhone(e.target.value)}
                        type="text"/>
                    <button onClick={() => onBindPhone()} className={"ml-2 bg-blue-500 text-white rounded p-2"}>绑定手机号</button>
                </p>}

                <p className="flex items-center mt-3 text-slate-600"><strong>授权：</strong>
                    {isVip && <span className="flex items-center text-purple-600"><Icon icon={vipCrown2Line} className={"mr-2"} /> 已购买会员</span>}
                    {!isVip && <span>未购买，{goBuy('点击这里购买')}</span>}
                </p>
                {isVip && <p className="mt-3 text-slate-600">
                    <strong>到期时间：</strong>
                    {!isForever && <>
                        <span>{subEnd}</span>
                        <span className={"ml-2"}> {goBuy('点击这里续费')} </span>
                    </>}
                    {isForever && <>
                        <span>终身使用权限</span>
                    </>}
                </p>}
                <div className="mt-10">
                    <button onClick={onLogout} className="w-25 bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 text-0.8em px-4 rounded">退出登录</button>
                </div>
            </div>}
        </>
    );
}