import {useEffect, useState} from "react";
import {Icon, addIcon} from "@iconify/react/dist/offline";
import loginCircleLine from "@iconify/icons-ri/login-circle-line";
import notification2Line from "@iconify/icons-ri/notification-2-line";
import sunline from "@iconify/icons-ri/sun-line";
import moonline from "@iconify/icons-ri/moon-line";
import message3Line from "@iconify/icons-ri/message-3-line";
import arrowGoBackLine from "@iconify/icons-ri/arrow-go-back-line";
import "../styles/header.css";
import {User} from "../utils/defines";
import {Link, useLocation, useNavigate} from "react-router-dom";

addIcon('i-ri:login-circle-line', loginCircleLine)
addIcon('i-ri:notification-2-line', notification2Line)
addIcon('i-ri:sun-line', sunline)
addIcon('i-ri:moon-line', moonline)
addIcon('i-ri:message-3-line', message3Line);
addIcon('i-ri:arrow-go-back-line', arrowGoBackLine);


export default function Header(props: {
    user: User | null
}) {
    const [iconTheme, setIconTheme] = useState(sunline);
    const location = useLocation();
    const [newMessage, setNewMessage] = useState(false);
    const navigate = useNavigate();

    function handleThemeToggle() {
        const element = document.documentElement
        const theme = element.getAttribute('data-theme');
        updateTheme(theme === 'dark' ? 'light' : 'dark');
        const isDark = theme === 'dark';
        localStorage.setItem("data-theme", isDark ? "light" : "dark");
    }

    function updateTheme(theme: string) {
        const element = document.documentElement
        const isDark = theme === 'dark';
        element.classList.remove(isDark ? 'light' : 'dark');
        element.classList.add(theme);
        element.setAttribute('data-theme', theme);
        const link = document.getElementById("hilightjs-theme");
        link?.setAttribute("href", `/hilightjs/a11y-${theme}.css`);
        setIconTheme(isDark ? sunline : moonline);
    }

    useEffect(() => {
        const readed = localStorage.getItem("changelog-2023-05-28");
        if (readed == null) {
            setNewMessage(true);
        }
        const theme = localStorage.getItem("data-theme");
        if (theme !== null) {
            updateTheme(theme);
        }
    }, []);

    function goLogin() {
        return <Link onClick={(evt) => {
            evt.preventDefault();
            //@ts-ignore
            if (typeof WeixinJSBridge != 'undefined') {
                // eslint-disable-next-line no-restricted-globals
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx06c7ec3ded8a4dc3&redirect_uri=https://api.cutterman.cn/api/wx-auth&response_type=code&scope=snsapi_base&state=2#wechat_redirect"
            } else {
                navigate('/login');
            }
        }}  to="/login"> <Icon icon={loginCircleLine} width={20} height={20} /> </Link>
    }


    return (
        <header>
            <div className="flex justify-between items-start">
                <span className="w-100px h-100px logo">
                    {location.pathname === '/' && <img width={100} height={100} src={"/logo.svg"} alt={"logo"} />}
                    {(location.pathname === '/profile' || location.pathname === '/wechat') && props.user !== null && <img src={props.user!.avatar} alt="logo"/>}
                </span>
                <div className="flex nav">
                    {location.pathname === '/' && <>
                        {props.user == null && <div className="flex items-center cursor-pointer text-slate-500 mx-1 p-2 hover:bg-slate-200 dark:hover:bg-slate-600 hover:bg-op-10 rounded text-1.2em" >
                            {goLogin()}
                        </div>}
                        {props.user != null && <div className="flex items-center cursor-pointer text-slate-500 mx-1 p-2 hover:bg-slate-200 dark:hover:bg-slate-600 hover:bg-op-10 rounded text-1.2em">
                            <Link to="/profile"><img width="24" src={props.user.avatar} alt="" /></Link>
                        </div>}
                        <div className={"flex items-center cursor-pointer mx-1 p-2 hover:bg-slate-200 dark:hover:bg-slate-600 hover:bg-op-10 rounded" + (newMessage? " text-orange-600" : " text-slate-500")}>
                            <Link to="/changelog"><Icon icon={message3Line} width={20} height={20} /> </Link>
                        </div>
                        <div
                            className="flex items-center cursor-pointer mx-1 p-2 text-slate-500 hover:bg-slate-200 dark:hover:bg-slate-600 hover:bg-op-10 rounded text-1.2em">
                            <button onClick={handleThemeToggle}><Icon icon={iconTheme} width={20} height={20} /> </button>
                        </div>
                    </>}
                    {(/login|profile|buy/.test(location.pathname)) &&
                        <div className="flex items-center cursor-pointer mx-1 p-2 text-slate-500 hover:bg-slate-200 hover:bg-op-10 rounded text-1.2em">
                            <Link to={"/"} title="返回"><Icon icon={arrowGoBackLine} /></Link>
                        </div>}
                </div>
            </div>
            {location.pathname === '/' && <div className="flex items-center mt-4 text-2xl title">
                <span
                    className="text-transparent font-extrabold bg-clip-text bg-gradient-to-r from-yellow-300 to-red-700 mr-1">Learn.</span>
                <span className="ml-1 font-extrabold text-slate-7 dark:text-slate">AI</span>
            </div>}
        </header>
    )
}