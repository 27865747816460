import React, {useEffect} from 'react';
import Index from './pages/Index';
import {Route, Routes} from "react-router-dom";
import Login from "./pages/login";
import Profile from "./pages/profile";
import Buy from "./pages/buy";
import Changelog from "./pages/changelog";
import Wechat from "./pages/wechat";
import NotFound from "./pages/notfound";

function App() {

    useEffect(() => {
        document.querySelector("main")?.classList.remove("before")
        document.querySelector("main")?.classList.add("after");
    }, []);

  return (
      <>
      <Routes>
          <Route path="/" element={
              <main className={"before"}>
                  <NotFound />
              </main>
          }>
          </Route>
          <Route path="/login" element={
              <main className={"before"}>
                  <Login />
              </main>
          } />
          <Route path="/profile" element={
              <main className={"before"}>
                  <Profile />
              </main>
          } />
          <Route path="/buy" element={
              <main className={"before"}>
                  <Buy />
              </main>
          } />
          <Route path="/changelog" element={
              <main className={"before"}>
                  <Changelog />
              </main>
          } />
          <Route path="/wechat" element={
              <main className={"before"}>
                  <Wechat />
              </main>
          } />
      </Routes>
      </>
  );
}

export default App;
