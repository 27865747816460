import {HistoryItem, MessageItem, User} from "./defines";

//const host: string = process.env['NODE_ENV'] === 'development'? 'http://127.0.0.1' : 'https://www.cutterman.cn';
const host: string = 'https://api.cutterman.cn';

export interface Response { errno: number; info: string }
export interface LoginInfo extends Response {
    access_token: string;
    is_wechat_bind: number;
}

export interface AliPayInfo extends Response {
    html: string;
    order_id: string;
}

export interface WechatPrePay {
    appId: string,
    timeStamp: string,
    nonceStr: string,
    package: string,
    signType: string,
    paySign: string,
    prepay_id: string,
    order_id: string
}

export interface WechatPayInfo extends Response {
    result: WechatPrePay;
}

export interface OrderStatusInfo extends Response {
    order: {
        trade_status: number;
        order_id: string;
    }
}

export interface HistoryInfo extends Response {
    list: {
        current_page: number;
        data: HistoryItem[];
        next_page_url: string | null;
        prev_page_url: string | null;
    }
}

export interface SubscriptionInfo extends Response {
    subscription?: {
        app: string;
        subscribe_end: string;
        subscribe_start: string;
        type: number
    }
}

export interface OrderInfo extends Response {
    data: {
        order_id: string;
        price: number;
        name: string;
    }
}

export interface QRCodeInfo extends Response {
    qrcodeUrl: string;
    sessionId: number;
}

export interface MessageListInfo extends Response {
    list: MessageItem[];
}

async function request(action: string, params: any) {
    const accessToken = localStorage.getItem('access_token');
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (accessToken) {
        // @ts-ignore
        headers['Authorization'] = `Bearer ${accessToken}`;
    }
    const response = await fetch(host + action, {
        method: 'POST',
        headers: headers,
        body: Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&')
    });
    if (response.headers.get("content-type")?.includes("application/json")) {
        return response.json();
    }
    return await response.text();
}


/**
 * 获取用户信息
 */
export async function getUserInfo(): Promise<User> {
    return await request('/chat/user-info', {});
}

/**
 * 发送短信验证码
 */
export async function sendSMS(phone: string): Promise<Response> {
    const params = {
        phone: phone
    }
    return await request('/api/send-sms', params);
}

/**
 * 登录
 */
export async function login(phone: string, code: string): Promise<LoginInfo> {
    const params = {
        phone: phone,
        code: code
    }
    const res =  await request('/api/login-sms', params);
    if (res.errno === 0) {
        localStorage.setItem('access_token', res.access_token);
    }
    return res;
}

export async function logout(): Promise<Response> {
    const r =  await request('/api/logout', {});
    if (r.errno === 0) {
        localStorage.removeItem('access_token');
    }
    return r;
}

export async function alipay(order_id: string): Promise<string> {
    return await request('/api/new-trade', {order_id, access_token: localStorage.getItem('access_token')});
}

export async function wechatPay(open_id: string, type: number): Promise<WechatPayInfo> {
    return await request('/chat/wechat-pay', {open_id: open_id, type: type});
}

export async function orderStatus(orderId: string): Promise<OrderStatusInfo> {
    return await request('/api/order-info', {order_id: orderId});
}

export async function addMessage(sessionId:string, content: string, role: string): Promise<Response> {
    return await request('/chat/add-message', {content: content, role: role, session_id: sessionId});
}

export async function sessionHistory(page: number): Promise<HistoryInfo> {
    return await request('/chat/session-list?page='+page, {});
}

export async function deleteSession(sessionId: string) {
    return await request('/chat/delete-session', {session_id: sessionId});
}

export async function messageList(sessionId: string): Promise<MessageListInfo> {
    return await request('/chat/message-list', {session_id: sessionId});
}

export async function subscription(): Promise<SubscriptionInfo> {
    return await request('/chat/subscription', {});
}

export async function newOrder(sku_id: number): Promise<OrderInfo> {
    return await request('/api/new-order', {sku_id});
}

export async function getWechatQRCode(): Promise<QRCodeInfo> {
    return await request('/api/get-wechat-qrcode', {sessionId: 0});
}

export async function getQrCodeStatus(sessionId: number): Promise<LoginInfo> {
    const res = await request('/api/login-qrcode-status', {sessionId: sessionId});
    if (res.errno === 0) {
        localStorage.setItem('access_token', res.access_token);
    }
    return res;
}

export async function bindWechat(sessionId: number): Promise<Response> {
    return await request('/api/bind-wechat', {sessionId: sessionId});
}

export async function bindPhone(phone: string, code: string): Promise<Response> {
    return await request('/api/bind-phone', {phone, code});
}