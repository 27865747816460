
import {useEffect,  useState, Component} from "react";
import Header from "../components/Header";
import {useNavigate} from "react-router-dom";
import {getQrCodeStatus, getWechatQRCode, login, sendSMS} from "../utils/http";

interface LoginProps {

}

interface LoginState {
    phone: string;
    code: string;
    error: string;
    loading: boolean;
    sending: boolean;
    counter: number;
    loginType: "phone" | "wechat";
    qrCode: string | null;
    sessionId: number;
    timer: number;
}

export default class Login extends Component<LoginProps, LoginState> {
    timeoutId: number = 0;

    constructor(props: any) {
        super(props);
        this.state = {
            phone: "",
            code: "",
            error: "",
            loading: false,
            sending: false,
            counter: 60,
            loginType: "wechat",
            qrCode: null,
            sessionId: 0,
            timer: 60
        }
    }


    async sendCode() {
        const phoneNumber = this.state.phone;
        console.log(`phone number is ${phoneNumber}`);
        if (phoneNumber === '') {
            this.setState({error: "请输入手机号"});
            return
        }
        if (!/^1[3456789]\d{9}$/.test(phoneNumber)) {
            this.setState({error: "请输入正确的手机号"})
            return
        }
        this.setState({error: ""})
        const data = await sendSMS(phoneNumber);
        if (data.errno !== 0) {
            this.setState({error: data.info, sending: false})
            return;
        }
        this.setState({sending: true})
        this.countDown();
    }

    countDown() {
        if (this.state.counter <= 0) {
            this.setState({sending: false, counter: 60})
            return;
        }
        this.setState({counter: this.state.counter - 1})
        setTimeout(() => {
            this.countDown();
        }, 1000);
    }


    async onLogin ()  {
        const {phone, code, loading} = this.state;
        if (phone === "") {
            this.setState({error: "请输入手机号"})
            return
        }
        if (code === "") {
            this.setState({error: "请输入验证码"})
            return
        }
        this.setState({
            error: "",
            loading: true
        })
        const data = await login(phone, code);
        let error: string = "";
        if (data.errno === 0) {
            if (data.is_wechat_bind === 0) {
                window.location.href = "/wechat";
                return;
            }
            error = "登录成功，现在跳转..."
            setTimeout(() => {
                window.location.href = "/";
            }, 1000);
        } else {
            error = data.info;
        }
        this.setState({
            error: error,
            loading: false
        })
    }


    loadQrCode() {
        getWechatQRCode().then((res) => {
            if (res.errno === 0) {
                this.setState({
                    qrCode: res.qrcodeUrl,
                    sessionId: res.sessionId,
                    timer: 60
                })
                this.getStatus(res.sessionId);
            }
        });
    }

    getStatus(sessionId: number) {
        console.log(this.state.loginType);
        if (this.state.loginType === "phone") {
            clearTimeout(this.timeoutId);
            return;
        }
        if (this.state.timer <= 0) {
            clearTimeout(this.timeoutId);
            this.loadQrCode();
            return;
        }
        getQrCodeStatus(sessionId).then((res) => {
            if (res.errno === 0) {
                clearTimeout(this.timeoutId);
                window.location.href = "/"
            } else {
                this.setState({
                    timer: this.state.timer - 2
                })
                this.timeoutId = window.setTimeout(() => {
                    this.getStatus(sessionId);
                }, 2000);
            }
        });

    }

    componentDidMount() {
        this.loadQrCode();
    }

    render() {
        const {phone, code, error, loading, sending, counter, loginType, qrCode} = this.state;
        return (
            <>
                <Header user={null} />
                <div className="flex flex-col mt-6">
                    <h3 className="text-2xl">账号登录</h3>
                    <p className={"mt-3 text-slate-600"}><strong className={"text-amber-700"}>新用户</strong>请使用微信扫码登录。</p>
                    <p className={"mt-3 text-slate-600"}>已经用手机号登录的用户，请使用手机号登录。绑定微信后，可使用微信扫码登录。</p>
                    {loginType === 'wechat' && <div className={"mt-6 mb-4"}>
                        {qrCode !== null && <div className={"flex flex-col items-center w-36"}>
                            <img width={200} height={200} src={qrCode} alt="微信二维码" />
                            <p className={"text-slate-600 mt-3 text-sm"}>
                                <a onClick={(evt) => {
                                    evt.preventDefault();
                                    clearTimeout(this.timeoutId);
                                    this.setState({
                                        loginType: 'phone'
                                    })
                                }} href={"#"} className={"underline"}>切换到手机号登录</a>
                            </p>
                        </div>}
                    </div>}
                    {loginType === 'phone' && <>
                        <div className="mt-6 mb-4">
                            <span className="text-gray-600">手机号</span>
                            <label>
                                <input
                                    className="ml-3 resize-none px-2 py-2 rounded text-slate-7 dark:text-slate-500 bg-slate-200 bg-op-15 focus:bg-op-20 focus:ring-0 focus:outline-none placeholder:text-slate-400 placeholder:text-slate-400 placeholder:op-40"
                                    value={phone}
                                    onInput={(e: any) => this.setState({phone: e.target.value})}
                                    type="text"/>
                            </label>
                        </div>
                        <div>
                            <span className="text-gray-600">验证码</span>
                            <label>
                                <input
                                    className="w-24 ml-3 resize-none px-2 py-2 rounded text-slate-7 dark:text-slate-500 bg-slate-200 bg-op-15 focus:bg-op-20 focus:ring-0 focus:outline-none placeholder:text-slate-400 placeholder:text-slate-400 placeholder:op-40"
                                    value={code}
                                    onInput={(e: any) => this.setState({code: e.target.value})}
                                    type="text"/>
                            </label>
                            {!sending && <button onClick={this.sendCode.bind(this)} className="text-0.9em ml-2 text-blue-500 underline">获取验证码</button>}
                            {sending && <span className="text-0.9em ml-4 text-gray-600 ">{counter}s后重试</span>}
                        </div>
                        {error && <div className="ml-16 text-red-500 mt-2">{error}</div>}
                        <button
                            disabled={loading}
                            onClick={this.onLogin.bind(this)}
                            className="w-1/4 mt-4 ml-14 bg-gray-700 hover:bg-gray-800 text-white font-bold py-2 text-0.8em px-4 rounded">登录</button>

                        <p className={"text-slate-600 ml-14 mt-4 text-sm"}>
                            <a onClick={(evt) => {
                                evt.preventDefault();
                                this.setState({
                                    loginType: 'wechat'
                                })
                            }} href={"#"} className={"underline"}>切换到微信登录</a>
                        </p>
                    </>}
                </div>
            </>
        )
    }
}
