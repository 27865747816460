import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react/offline";
import arrowGoBackLine from "@iconify/icons-ri/arrow-go-back-line";
import checkLine from "@iconify/icons-ri/check-line";
import wechat2Line from "@iconify/icons-ri/wechat-2-line";
import {User} from "../utils/defines";
import {alipay, getUserInfo, newOrder, orderStatus, wechatPay} from "../utils/http";
import {addIcon} from "@iconify/react/dist/offline";
import Header from "../components/Header";

addIcon('check-line', checkLine);

export default function Buy(props: any) {
    const [user, setUser] = useState<User | null>(null);
    const [html, setHtml] = useState<string>('');
    const [status, setStatus] = useState<number>(-1);
    const [orderId, setOrderId] = useState<string>('');
    const [isWechat, setIsWechat] = useState<boolean>(false);
    const [sku_id, setSku_id] = useState(0);

    let timer: any = null;

    useEffect(() => {
        // 读取用户信息
        getUserInfo().then((res) => {
            setUser(res)
        });


        const result = new URLSearchParams(window.location.search);
        const openId = result.get("open_id");
        // 判断当前是否是微信环境
        //@ts-ignore
        if (typeof WeixinJSBridge != 'undefined' || openId !== null) {
            setIsWechat(true);
        } else {
        }


    }, []);

    useEffect(() => {
        if (status === 0 && !isWechat) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            timer = setInterval(() => {
                orderStatus(orderId).then((res) => {
                    if (res.order.trade_status === 1) {
                        setStatus(1);
                        clearInterval(timer);
                    }
                });
            }, 2000);
       }
        return () => clearInterval(timer);
    }, [status, orderId]);

    async function selectSku(sku_id: number) {
        setSku_id(sku_id);
        if (isWechat) {
            setStatus(0);
        } else {
            const res = await newOrder(sku_id);
            if (res.errno === 0) {
                const html = await alipay(res.data.order_id);
                setHtml(html);
                setStatus(0);
                setOrderId(res.data.order_id);
            }
        }
    }


    async function onPay(bucket: number) {
        const result = new URLSearchParams(window.location.search);
        const openId = result.get("open_id");
        if (openId === null) {
            alert("支付异常，请返回主界面后重试");
            return;
        }
        const prePay = await wechatPay(openId, bucket);
        const payInfo = prePay.result;
        if (payInfo) {
            setOrderId(payInfo.order_id);
            // @ts-ignore
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                    "appId": payInfo.appId,   //公众号ID，由商户传入
                    "timeStamp": payInfo.timeStamp,   //时间戳，自1970年以来的秒数
                    "nonceStr": payInfo.nonceStr,      //随机串
                    "package": payInfo.package,
                    "signType": "RSA",     //微信签名方式：
                    "paySign": payInfo.paySign //微信签名
                },
                function(res: any) {
                    alert(res.err_msg);
                    if (res.err_msg === "get_brand_wcpay_request:ok") {
                        alert("支付成功");
                        orderStatus(payInfo.order_id).then((res) => {
                            if (res.order.trade_status === 1) {
                                setStatus(1);
                                getUserInfo();
                            }
                        });
                    }
                });
        }
        //alert(`prepayId: ${prepayId}`);
    }


    return (
        <>
            <Header user={user}/>

            <div className="flex flex-col mt-5">
                <h2 className="text-3xl text-slate-600 dark:text-slate-500">成为会员</h2>
                <p className="mt-3 text-slate-600 dark:text-slate-500"><strong>账号：</strong> <span className="text-orange-600">{user? user.phone : ''}</span></p>
                <p className="mt-3 text-slate-600 dark:text-slate-500"><strong>选择套餐：</strong> </p>

                <div className={"flex flex-row gap-4 mt-4"}>
                    <button onClick={() => selectSku(33)} className={"rounded hover:bg-emerald-700 text-white text-center px-6 py-4" + ((sku_id === 33 || sku_id === 0)? ' bg-emerald-600' : ' bg-gray-500')}>
                        <h3 className={"text-slate-200 text-sm"}>季度会员</h3>
                        <p className={"mt-2 text-lg"}>38￥/季度</p>
                    </button>
                    <button onClick={() => selectSku(34)} className={"rounded hover:bg-cyan-700 text-white text-center px-6 py-4" + ((sku_id === 34 || sku_id === 0)? ' bg-cyan-600' : ' bg-gray-500')}>
                        <h3 className={"text-slate-200 text-sm"}>半年会员</h3>
                        <p className={"mt-2 text-lg"}>68￥/半年</p>
                    </button>
                    <button onClick={() => selectSku(35)} className={"rounded hover:bg-violet-700 text-white text-center px-6 py-4" + ((sku_id === 35 || sku_id === 0)? ' bg-violet-600' : ' bg-gray-500')}>
                        <h3 className={"text-slate-200 text-sm"}>年度会员</h3>
                        <p className={"mt-2 text-lg"}>98￥/一年</p>
                    </button>
                    <button onClick={() => selectSku(36)} className={"rounded hover:bg-fuchsia-700 text-white text-center px-6 py-4" + ((sku_id === 36 || sku_id === 0)? ' bg-fuchsia-600' : ' bg-gray-500')}>
                        <h3 className={"text-slate-200 text-sm"}>终身会员</h3>
                        <p className={"mt-2 text-lg"}>198￥/终身</p>
                    </button>
                </div>

                {isWechat && status === 0 &&<>
                    *<button onClick={() => onPay(sku_id)} id="pay-button" className="flex items-center justify-center w-1/2 p-6 mt-2 hover:bg-green-800 bg-green-700 text-white rounded h-10">
                    <Icon icon={wechat2Line} className="mr-2" />
                        <span>微信支付</span>
                    </button>
                </>}
                {!isWechat && html !== '' && status === 0 && <>
                    <p className="mt-4 mb-2 text-slate-600 dark:text-slate-500">用<strong className="text-blue-600">支付宝</strong>扫描下面二维码完成支付 </p>
                     <div id="iframe">
                        <iframe style={{
                            border: 'none',
                            width: "360px",
                            height: "320px",
                            paddingTop: "30px",
                            background: "white"
                        }} scrolling={"no"} title={"iframe1"} srcDoc={html}/>
                    </div>
                </>}

                {status === 1 && <div id="complete" className="mt-6 bg-emerald-600 flex items-center mx-1 p-2 rounded text-xl text-white" style={{width: "300px"}}>
                    <Link to="/profile" className="flex items-center" title="返回">
                        <Icon icon={checkLine} className={"mr-2"} />
                        <span>支付成功，感谢您的支持！</span>
                    </Link>
                </div>}

                <h2 className="text-3xl mt-10 text-slate-600 dark:text-slate-500">会员权益</h2>
                <p className="mt-3 text-slate-600 dark:text-slate-500">为了使本站点能够持续稳定的提供服务，将采用付费订阅使用机制。会员可以享受如下权益：</p>
                <p className="mt-4 text-slate-800 dark:text-slate-500">1. AI对话无限制使用</p>
                <p className="mt-2 text-slate-800 dark:text-slate-500">2. 支持查看以往对话记录</p>
                <p className="mt-2 text-slate-800 dark:text-slate-500">3. 享受站点功能升级</p>

                <h2 className="text-3xl mt-10 text-slate-600 dark:text-slate-500">付费说明</h2>
                <p className="mt-3 text-slate-600 dark:text-slate-500">本站的初衷是能够让个人方便快捷的使用AI能力来提升自我和日常工作效率，并不以盈利为目的，为了保障使用体验，站点不会挂任何广告或者其它宣传引流的操作。采用付费制主要基于以下考虑：</p>
                <p className="mt-4 text-slate-800 dark:text-slate-500">1. 防止流量过大，带来恶意滥用，给站点带来不可预期的风险</p>
                <p className="mt-2 text-slate-800 dark:text-slate-500">2. 支付openai的api调用费用</p>
                <p className="mt-2 text-slate-800 dark:text-slate-500">3. 支付本站点服务器、数据库、带宽等费用</p>
                <p className="mt-5 text-slate-600 dark:text-slate-500">所以，对于付费用户，支持如下<strong
                    className="text-orange-600 dark:text-slate-500">退款政策：</strong></p>
                <p className="mt-3 text-slate-800 dark:text-slate-500">1. 若站点因不可抗力因素无法继续提供服务，将支持退还用户尚未到期的使用时间所折算的费用，按<strong>100%</strong>比例退还。计算规则如下：<br/>
                    <strong>(((最后一次使用的日期 - 购买当天日期)/365) x 支付金额) x 100%</strong></p>
                <p className="mt-3 text-slate-800 dark:text-slate-500">2.
                    若个人意愿中途不想继续使用，按照尚未到期的使用时间所折算的费用，按<strong>70%</strong>比例退还。计算规则如下：<br/>
                    <strong>(((最后一次使用的日期 - 购买当天日期)/365) x 支付金额) x 70%</strong>
                </p>
                <p className="mt-3 text-slate-800 dark:text-slate-500">3. 购买终身会员，使用两年后不支持退款。 </p>
                <p className="mt-3 text-slate-800 dark:text-slate-500">退款渠道，请加微信交流群，联系群主操作。</p>
                <p className="mt-3"><img width={200} src="/learn-ai-wechat-group.jpg" alt="" /></p>

            </div>
        </>
    )
}
